const MS_PER_SECOND = 1000
const MS_PER_MINUTE = MS_PER_SECOND * 60
const MS_PER_HOUR = MS_PER_MINUTE * 60
const MS_PER_DAY = MS_PER_HOUR * 24
const MS_PER_MONTH = MS_PER_DAY * 30
const MS_PER_YEAR = MS_PER_MONTH * 365

const dateFormatter = new Intl.DateTimeFormat('nb-NO', {
  year: 'numeric',
  month: '2-digit',
  day: '2-digit',
  hour: '2-digit',
  minute: '2-digit',
})

/**
 * Formats a date to a string as relative to
 *
 * @param {number|string|Date|undefined} value The date to format
 * @param {Intl.RelativeTimeFormat} options The formatter options
 * @return {string|undefined}
 */
export const dateToRelative = (
  value: number | string | Date | undefined,
  locale = 'en-GB',
  options?: Intl.RelativeTimeFormatOptions,
): string | undefined => {
  const previous = value ? new Date(value) : undefined

  if (!previous || isNaN(previous.getTime())) {
    return undefined
  }

  const current = new Date()
  const elapsed = current.getTime() - previous.getTime()
  let timeValue = 0
  let unit: Intl.RelativeTimeFormatUnit = 'second'

  if (elapsed < MS_PER_MINUTE) {
    timeValue = Math.round(elapsed / MS_PER_SECOND)
    unit = 'second'
  } else if (elapsed < MS_PER_HOUR) {
    timeValue = Math.round(elapsed / MS_PER_MINUTE)
    unit = 'minute'
  } else if (elapsed < MS_PER_DAY) {
    timeValue = Math.round(elapsed / MS_PER_HOUR)
    unit = 'hour'
  } else if (elapsed < MS_PER_MONTH) {
    timeValue = Math.round(elapsed / MS_PER_DAY)
    unit = 'day'
  } else if (elapsed < MS_PER_YEAR) {
    timeValue = Math.round(elapsed / MS_PER_MONTH)
    unit = 'month'
  } else {
    timeValue = Math.round(elapsed / MS_PER_YEAR)
    unit = 'year'
  }

  return new Intl.RelativeTimeFormat(locale, {
    numeric: 'auto',
    ...options,
  }).format(-1 * timeValue, unit)
}

/**
 * Formats a date to a string
 *
 * @param {number|string|Date|undefined} value The date to format
 * @return {string|undefined}
 */
export const dateFormatted = (
  value: number | string | Date | undefined,
): string | undefined => {
  const date = value ? new Date(value) : undefined

  return !date || isNaN(date.getTime()) ? undefined : dateFormatter.format(date)
}
